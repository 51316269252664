import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`How to check your development environment is ready for working on KOPE.`}</p>
    </PageDescription>
    <h2>{`Checking .NET Requirements`}</h2>
    <h4>{`To check you have the .NET 5.0 Runtime Installed`}</h4>
    <ol>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`Windows + R`}</inlineCode>{` shortcut to open the Windows Run prompt.`}</li>
      <li parentName="ol">{`Type `}<inlineCode parentName="li">{`regedit`}</inlineCode>{` and hit the Enter key to open the Registry Editor.`}</li>
      <li parentName="ol">{`Expand the following subfolder `}<inlineCode parentName="li">{`Computer\\HKEY_LOCAL_MACHINE\\SOFTWARE\\dotnet\\Setup\\InstalledVersions\\x64\\sharedhost`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`If you do have this subfolder, the Version should begin with ‘5.’, something like ‘5.0.0’. `}</li>
      <li parentName="ol">{`If you do not have this subfolder, you have not installed .NET 5.0.`}</li>
    </ol>
    <h2>{`Checking NuGet Packages`}</h2>
    <ol>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`Windows + E`}</inlineCode>{` to open Windows Explorer.`}</li>
      <li parentName="ol">{`Enter the following in the address bar: `}<inlineCode parentName="li">{`%userprofile%\\.nuget\\packages`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`If you have a folder for ‘kope.workflows.sdk.codegeneration’, the NuGet package has been installed.`}</li>
      <li parentName="ol">{`If you are lacking this folder, ensure the `}<a parentName="li" {...{
          "href": "download-kope-nuget-packages"
        }}>{`NuGet instructions`}</a>{` were followed.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      